import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NavBar from "../Navbar/Navbar";
import { getDatabase, ref, onValue } from "firebase/database";
import { app } from "../../utils/firebase-config";
import "./Tab.css";

const Tab = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState();
  const [role, setRole] = useState("");
  const [openTab, setOpenTab] = useState(0);
  const [openSubTab, setOpenSubTab] = useState(0);
  const [dataIndex, setDataIndex] = useState(0);
  const [dataSubIndex, setDataSubIndex] = useState(0);

  const queryMenu = async () => {
    const db = getDatabase(app);
    const startCountRef = ref(db);
    onValue(startCountRef, (snapshot) => {
      const data = snapshot.val();
      const dataList = [];
      data.data.map((menu) => {
        dataList.push(menu);
      });

      if (checkUserId()) {
        const data = dataList.map((element) => { 
            return {...element, subTab: element.subTab.filter((subTab) => { 
             //  return {...subTab, contentDownload: subTab.contentDownload.filter((content) => content.role === 'TWZ') }
              return subTab.contentDownload.some((content) => content.role === 'TWZ')



              // return subTab.filter((content) => content.contentDownload.some((content) => content.role === 'TWZ'))

            //  return { ...subTab.contentDownload.filter((content) => content.role === 'TWZ') }
               
            }).map((item) => {  return { ...item, contentDownload: item.contentDownload.filter((content) => content.role === 'TWZ') }})} 
        });

        // console.log('data', data);

        setMenu(data);
      } else {
        setMenu(dataList);
      }
     
    });
  };

  const checkUserId = () => {
    const userId = sessionStorage.getItem("userId");
    // if (userId === 'wIQFlaCKk7ajr6SVliT54y0pQWD2') {
    //   setRole('TWZ');
    // }
    return userId === "DtI9TC5X54N3zvkyCFnEkcbCwt73" ? true : false;
  };

  useEffect(() => {
    const authToken = sessionStorage.getItem("Auth Token");
    if (authToken) {
      navigate("/download");
    }

    if (!authToken) {
      navigate("/login");
    }

    checkUserId();
    queryMenu();
  }, []);

  return (
    <div>
      <NavBar />
      <div className="px-8">
        <div className="flex flex-wrap">
          <div className="w-screen">
            <main>
              <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {/* <!-- Replace with your content --> */}
                <div className="px-1 py-6 sm:px-0">
                  <div className="border-2 border-dashed border-gray-200  glass rounded-lg ">
                    <ul
                      className="flex justify-center mb-0 list-none flex-wrap pt-3 pb-12 flex-row"
                      role="tabList"
                    >
                      {menu ? (
                        menu?.map((data, index) => (
                          <div className="items-center pt-6" key={index}>
                            <div className="inline-flex">
                              {/* <button
                                className={
                                  `w-96 
                           px-12 
                           py-5 
                           sm:w-auto 
                           text-white  
                           inline-flex 
                           items-center 
                           justify-center                             
                           ext-xs 
                           font-bold 
                           uppercase 
                         
                           border-1 
                           border-indigo-700 
                           drop-shadow-lg 
                           block 
                           leading-normal ` +
                                  (openTab === index
                                    ? "text-white bg-slate-900 -600"
                                    : "text-slate-600 -600 bg-white")
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenTab(index);
                                  setDataIndex(index);

                                  // set default sub tab
                                  setOpenSubTab(0);
                                  setDataSubIndex(0);
                                }}
                              >
                                {data.menu.headerTab}
                              </button> */}

                              <button
                                className={
                                  `w-60` +
                                  (openTab === index
                                    ? " push-able-header-active"
                                    : " push-able-header")
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenTab(index);
                                  setDataIndex(index);

                                  // set default sub tab
                                  setOpenSubTab(0);
                                  setDataSubIndex(0);
                                }}
                              >
                                <span
                                  className={
                                    openTab === index
                                      ? "front-header-active"
                                      : "front-header"
                                  }
                                >
                                  {data.headerTab}
                                </span>
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <Skeleton count={10} />
                      )}
                    </ul>

                    <hr className="border-hr" />

                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6  rounded">
                      <div className="flex-auto">
                        <div className="tab-content tab-space">
                          <div
                            className={
                              openTab === dataIndex ? "block" : "hidden"
                            }
                            id="link1"
                          >
                            <ul
                              className="flex justify-center mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                              role="subTab"
                            >
                              {menu ? (
                                menu[dataIndex]?.subTab.map(
                                  (subTab, subIndex) => (
                                    <div
                                      className="items-center pt-6"
                                      key={subIndex}
                                    >
                                      <div className="inline-flex">
                                        {/* <button
                                          className={
                                            `w-60 
                                
                                            btn b-2
                                
                                            ` +
                                            (openSubTab === subIndex
                                              ? "text-white bg-indigo-700 -600"
                                              : "text-indigo-600 -600 bg-white")
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setOpenSubTab(subIndex);
                                            setDataSubIndex(subIndex);
                                          }}
                                        >
                                          {subTab.name}
                                        </button> */}
                                        <button
                                          className={
                                            `w-60` +
                                            (openSubTab === subIndex
                                              ? " push-able-active"
                                              : " push-able")
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setOpenSubTab(subIndex);
                                            setDataSubIndex(subIndex);
                                          }}
                                        >
                                          <span
                                            className={
                                              openSubTab === subIndex
                                                ? "front-active"
                                                : "front"
                                            }
                                          >
                                            {subTab.name}
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <Skeleton count={10} />
                              )}
                            </ul>

                            <hr className="border-hr" />

                            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6">
                              <div className="px-4 py-5 flex-auto">
                                <div className="tab-content tab-space">
                                  <div
                                    className={
                                      openSubTab === dataSubIndex
                                        ? "block"
                                        : "hidden"
                                    }
                                    id="link1"
                                  >
                                    {menu ? (
                                      menu[dataIndex].subTab[
                                        dataSubIndex
                                      ]?.contentDownload.map(
                                        (contentDownload, index) => (
                                          <div className="pt-6" key={index}>
                                            <div
                                              className="p-4 w-full text-center bg-gray-100 rounded-t-lg border-2 shadow-md sm:p-8 glass"
                                              style={{
                                                borderColor:
                                                  contentDownload?.backgroundColor,
                                              }}
                                            >
                                              <h5 className="mb-2 text-3xl font-bold  text-gray-800">
                                                {contentDownload?.name}
                                              </h5>

                                              <p className="text-base text-gray-800 sm:text-lg">
                                                Version:{" "}
                                                {contentDownload?.version}
                                              </p>
                                              {/* <p className="text-base text-gray-500 sm:text-md dark:text-gray-400">
                                        Update Date:
                                        {contentDownload.updateDate}
                                      </p> */}

                                              {contentDownload?.expireDate ? (
                                                <p className="mb-3 text-base text-gray-800  sm:text-md">
                                                  Expire Date:{" "}
                                                  {contentDownload?.expireDate}
                                                </p>
                                              ) : null}
                                              <p
                                                className="mb-3 cursor-pointer underline text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 uppercase last:mr-0 mr-1"
                                                onClick={() => {
                                                  navigate("/description", {
                                                    state: {
                                                      description:
                                                        contentDownload?.description,
                                                      backgroundColor:
                                                        contentDownload?.backgroundColor,
                                                    },
                                                  });
                                                }}
                                              >
                                                # Description
                                              </p>

                                              <div className="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                                                {menu[dataIndex].headerTab ===
                                                "IOS" ? (
                                                  <a
                                                    href={
                                                      contentDownload?.downloadLink
                                                    }
                                                    className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                                                  >
                                                    <svg
                                                      className="mr-3 w-7 h-7"
                                                      aria-hidden="true"
                                                      focusable="false"
                                                      data-prefix="fab"
                                                      data-icon="apple"
                                                      role="img"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 384 512"
                                                    >
                                                      <path
                                                        fill="currentColor"
                                                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                                                      ></path>
                                                    </svg>
                                                    <div className="text-left">
                                                      <div className="mb-1 text-xs">
                                                        Download for
                                                      </div>
                                                      <div className="-mt-1 font-sans text-sm font-semibold">
                                                        Apple device
                                                      </div>
                                                    </div>
                                                  </a>
                                                ) : (
                                                  <a
                                                    href={
                                                      contentDownload?.downloadLink
                                                    }
                                                    className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                                                  >
                                                    <svg
                                                      className="mr-3 w-7 h-7"
                                                      aria-hidden="true"
                                                      focusable="false"
                                                      data-prefix="fab"
                                                      data-icon="google-play"
                                                      role="img"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 512 512"
                                                    >
                                                      <path
                                                        fill="currentColor"
                                                        d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"
                                                      ></path>
                                                    </svg>
                                                    <div className="text-left">
                                                      <div className="mb-1 text-xs">
                                                        Download for
                                                      </div>
                                                      <div className="-mt-1 font-sans text-sm font-semibold">
                                                        Android Device
                                                      </div>
                                                    </div>
                                                  </a>
                                                )}
                                              </div>
                                            </div>
                                            <footer
                                              className="rounded-b-lg bg-gray-100 text-sm text-gray-800 px-8 py-3 text-right font-sans text-sm font-semibold text-gray-900 dark:text-white"
                                              style={{
                                                backgroundColor:
                                                  contentDownload?.backgroundColor
                                                    ? contentDownload?.backgroundColor
                                                    : "#ffff",
                                              }}
                                            >
                                              Update date :{" "}
                                              {contentDownload?.updateDate}
                                            </footer>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <Skeleton count={10} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /End replace --> */}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
