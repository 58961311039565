import React from "react";
import { useForm } from "react-hook-form";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Download from "../../assets/images/downloads.png";
import "./LoginPage.css";

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  // const onSubmit = (data) => console.log(data);

  const onSubmit = (data) => {
    const authentication = getAuth();
    signInWithEmailAndPassword(authentication, data.email, data.password)
      .then((response) => {
        navigate("/download");
        sessionStorage.setItem("Auth Token", response._tokenResponse.refreshToken);
        sessionStorage.setItem("userId", response._tokenResponse.localId);
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          toast.error("Wrong password!");
        }

        if (error.code === "auth/user-not-found") {
          toast.error("User not found!");
        }

        if (error.code === "auth/invalid-email") {
          toast.error("Invalid email!");
        }
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <ToastContainer />
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg glass">
        <div className="flex justify-center">
          <img
            className="rounded-tl-xl w-20 object-cover"
            alt="downloads"
            src={Download}
          />
        </div>
        <h3 className="text-2xl font-bold text-center pt-8 text-gray-800">
          IDPASSGLOBAL APPLICATION DOWNLOAD
        </h3>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4">
            <div>
              <label className="block  text-gray-800" htmlFor="email">
                Email : 
              </label>

              <input
                type="text"
                placeholder="Email"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-red-500 text-xs italic">
                  Please choose a email.
                </p>
              )}
            </div>

            <div className="mt-4">
              <label className="block  text-gray-800">Password</label>
              <input
                type="password"
                placeholder="Password"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <p className="text-red-500 text-xs italic">
                  Please choose a password.
                </p>
              )}
            </div>

            <div className="flex items-baseline justify-between">
              <button
                type="submit"
                className="px-6 py-2 mt-4 text-white bg-blue-600 w-full rounded-lg hover:bg-blue-900"
              >
                Login
              </button>
            </div>
          </div>
        </form>
        <p className="text-center text-gray-500 text-xs">
          &copy;{new Date().getFullYear()} Id Pass global Corp. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
