import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useLocation } from "react-router-dom";

const Description = () => {
  const { state } = useLocation();
  const { description, backgroundColor } = state;
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-1 py-6 sm:px-0">
            <div className="border-2 border-dashed border-gray-200 rounded-lg ">
              <div className="px-8  justify-center min-h-screen">
                <div className="pt-6">
                  <div
                    className="p-4 w-full text-center bg-white rounded-t-lg border-2 shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                    style={{
                      borderColor: backgroundColor,
                    }}
                  >
                    <h5 className="mb-2 text-3xl font-bold text-gray-900 dark:text-white border-b border-gray-300">
                      Feature and fix
                    </h5>

                    <div className="mb-2">
                      <span className="text-base text-gray-600 sm:text-lg dark:text-gray-400">
                        <ul>
                          {description
                            ? description.map((data, index) => (
                                <li
                                  key={index}
                                  className="mb-3 text-lg font-semibold py-1 px-2 uppercase rounded text-pink-600 uppercase last:mr-0 mr-1"
                                >
                                  {index + 1}. {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </span>
                    </div>

                    <div className="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                      <a className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                        <div className="text-left">
                          <div
                            className="-mt-1 font-sans text-sm font-semibold cursor-pointer"
                            onClick={() => navigate("/download")}
                          >
                            Go to download page
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <footer
                    className="rounded-b-lg bg-gray-100 text-sm text-gray-500 px-8 py-3 text-right font-sans text-sm font-semibold text-gray-900 dark:text-white"
                    style={{
                      backgroundColor: backgroundColor,
                    }}
                  ></footer>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Replace with your content --> */}
      </main>
    </>
  );
};

export default Description;
