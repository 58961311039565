import { initializeApp,  } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAsL_lw1SI2uJNnQR4ugaYpE9hE4OHh1YA",
  authDomain: "idpass-ipa-download.firebaseapp.com",
  projectId: "idpass-ipa-download",
  storageBucket: "idpass-ipa-download.appspot.com",
  messagingSenderId: "190272294835",
  appId: "1:190272294835:web:8dc6f66c1861faa77f05c6",
};


export const app = initializeApp(firebaseConfig);
