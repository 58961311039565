import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import Tab from "./components/Tab/Tab";
import Description from "./components/Description/Description";
import "./App.css";

function App() {
  return (
    <div className="h-screen">
      {/* <LoginPage /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LoginPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/download" element={<Tab />}></Route>
          <Route path="/description" element={<Description />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
